import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import  SystemConstants  from '../config/SystemConstants'


function UploadWindow({ onClose, refreshSidebar}) {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('./upload-2.png');
  const [fileName, setFileName] = useState(''); 

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result); 
      };
      fileReader.readAsDataURL(selectedFile);
    }
  };

  const handleUpload = () => {
    if (!file) {
      alert('請先選擇一個檔案');
      return;
    };
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const base64 = fileReader.result;
  
      fetch(SystemConstants.API_URL.IMAGE.uploadImageDetail, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {
            fileName: fileName,
            imageFileBase64: base64,
            imageStatus: 'UNMARK'
        }),
      })
      .then(response => response.json())
      .then(data => {
        onClose(); 
        // 完成上傳後，call callback 刷新Sidebar列表
        refreshSidebar();
      })
      .catch(error => console.error('Error:', error));
      };
      fileReader.readAsDataURL(file); 
  };

  return (
    <div className='flex flex-col items-center justify-between w-[40%] h-[50%] border-[2px] border-gray fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-50 bg-white shadow-lg rounded-[20px] overflow-hidden'>
      <header className='flex flex-col justify-between w-[100%] bg-[#F2F2F2] p-[12px] h-1/5 '>
        <div className='flex justify-between '>
          <p className='text-[17px] font-semibold'>上傳圖片</p>
          <CloseOutlined onClick={onClose}/>
        </div>
        <div className='flex justify-between w-[72px]'>
          <button className='text-[13px]'>上傳</button>
          <p className='text-[13px]'>拍攝</p>
        </div>
      </header>
      <div className='flex flex-col justify-around items-center h-[60%] mb-[40px]'>
        <img src={previewUrl}  alt='defaultpic' className='w-[191px] min-h-[191px] object-contain'/>
        <p>{file ? file.name : ''}</p>
        <label className='bg-[#B3E4B8] border-[1px] border-[#C2C2C2] p-[5px] rounded-[5px]'>
          {file ? <button onClick={handleUpload}>確認上傳</button>
           : 
           '瀏覽'}
          <input type="file" onChange={handleFileChange} className='hidden'/>
        </label>
      </div>
      <div>
        <div className='flex flex-col'>
        </div>
      </div>
    </div>
  );
}

export default UploadWindow;

