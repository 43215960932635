import React, { useState, useEffect } from 'react';
import SystemConstants from '../config/SystemConstants';
import UploadWindow from './UploadWindow'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

// icon
import { FolderOutlined, FolderOpenOutlined, PictureOutlined, RightOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';

const FileSidebar = ({choose, refreshSidebar}) => {
  const [fileList, setFileList] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedButton, setSelectedButton] = useState('ALL');
  const [markingImageId, setMarkingImageId] = useState(0)

  // 打取得資料夾內容 API 
  const api_getPathInfo = async (path) => {

    console.trace('追蹤使用');

    try {
      if(path === '/'){
        path = '';
      }

      const response = await fetch(SystemConstants.API_URL.IMAGE.getPathInfo + path);
      const data = await response.json();
      return data.responseData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  // 處理 click 檔案的內容，並返回 false 以阻止事件冒泡
  const handleFileClick = (fileItem, fileIndex) => {
    // 刷新主要圖片
    choose({'imageId': fileItem.imageId});   

    setMarkingImageId(fileItem.imageId);
    
    // 返回 false 以阻止事件冒泡
    return false;
  };

  // 關閉資料夾 click
  const handleFolderCloseClick = async (item, index, indexArray) => {
    console.trace('Printing stack trace:');

    let folderArray = [];

    let pathArray = indexArray.split(',');
      
    // 使用物件傳值得機制，先使用迴圈找到最後資料夾的記憶體位置，後續再直接更新 fileList
    for (let i = 0; i < pathArray.length; i++) {

      if(folderArray.length===0){
        folderArray[i] =  fileList.folderList[[pathArray[i]]]
      } else{
        folderArray.push(folderArray[i-1].folderList[[pathArray[i]]]);
      }
    }


    let lastFolder = folderArray[folderArray.length-1]

    lastFolder.isOpen = false;
    setFileList(chargeFileList())

  }

  // 強制刷新 fileList
  const chargeFileList = () => {
    return {isChang: !fileList.isChang, folderList: fileList.folderList}
  }

  // 開啟資料夾 click
  const handleFolderOpenClick = async (item, index, indexArray) => {


    console.trace('Printing stack trace:');

    indexArray = indexArray + ',' + index;

    // 拿下一層資料夾(設邊需要 fullName)
    const responseData = await api_getPathInfo(item.fullName);
    
    if(responseData){
      // 取得指定當層的資料(整理出檔案 + 資料夾 列表)
      let pathInList = pathInfoTodisplayList(responseData);

      let pathArray = indexArray.split(',');

      let folderArray = [];
      
      // 使用物件傳值得機制，先使用迴圈找到最後資料夾的記憶體位置，後續再直接更新 fileList
      for (let i = 0; i < pathArray.length; i++) {
        if(folderArray.length===0){
          folderArray[i] =  fileList.folderList[[pathArray[i]]]
        } else{
          folderArray.push(folderArray[i-1].folderList[[pathArray[i]]]);
        }
      }

      let lastFolder = folderArray[folderArray.length-1]
      lastFolder.folderList = pathInList.folderList;
      lastFolder.fileList = pathInList.fileList;
      lastFolder.isOpen = true;


      setFileList(chargeFileList())
    }
  };

  // init
  useEffect(() => {
    const fetchData = async () => {
      const responseData = await api_getPathInfo('/');

      if(responseData){

        let result = await pathInfoTodisplayList(responseData);
        setFileList({
          isChang: true,
          folderList:[{
          type: 'folder',
          name: '/',
          isOpen: true,
          fileList: result.fileList,
          folderList: result.folderList
        }]});
      }
    };

    fetchData();
  }, []);

  // 監控 fileList 解問題用
  useEffect(() => {
  }, [fileList]);


  // 取得單層字串   ex. / --> /     aa/bb/cc/ -> cc/    
  const getSinglePathName = (fileString) => {
    return fileString.split('/')[fileString.split('/').length-2] + '/';
    
  }

  const deleteImage =(imageId) => {
    fetch(SystemConstants.API_URL.IMAGE.deleteImage, {
        method: 'DELETE',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(
        {
            imageId: imageId              
        }),
    })
    .then(response => response.json())
    .then(data => {
        // 刪除照片後，透過 callBack 刷興縮圖列表
        refreshSidebar();
    })
    .catch(error => console.error('Error:', error));
  };

  const pathInfoTodisplayList = (responseData) => {
    let result = {};
    if(responseData){
      // 檔案清單
      if(responseData.fileList){
        responseData.fileList.forEach((file) => {
          if(file){
            if(!result.fileList){
              result.fileList = []
            }
            result.fileList.push({
              type: 'file',
              name: file.fileName,
              imageId: file.imageId,
              imageUrl: file.imageUrl,
              imageStatus: file.imageStatus
            })
          }
        });
      }

      // 資料夾清單
      if(responseData.folderNameList){
        responseData.folderNameList.forEach((folder, index) => {
          if(folder){
            
            if(!result.folderList){
              result.folderList = [];
            }

            result.folderList.push({
              type: 'folder',
              name: getSinglePathName(folder),
              fullName: folder,
              isOpen: false
            })
          }
        });
      }
    }
    return result;
  };


  // 遞迴長出整個檔案系統
  const renderItems = (items, folderLevel, indexArray) => {

    if(items && Object.keys(items).length !== 0){
      return items.map((item, index) => (
        
        <div key={index}>

          {/* 關閉中的資料夾 */}
          {item.type === 'folder' && !item.isOpen ?
            <li onClick={() => handleFolderOpenClick(item, index, indexArray)}>
              <> <span>{'　'.repeat(folderLevel)}</span> <RightOutlined /> <FolderOutlined /> {item.name}</> 
            </li>
          : null}

          {/* 開啟中的資料夾 */}
          {item.type === 'folder' && item.name !== '/' && item.isOpen ? 
            <li onClick={() => handleFolderCloseClick(item, index, indexArray==='' ? '0' : indexArray + ',' + index)}>
              <><span>{'　'.repeat(folderLevel>0?folderLevel:0)}</span> <DownOutlined /> <FolderOpenOutlined /> {item.name}</> 
            </li>
          : null}

          {/* 下一層資料夾 */}
          {item && item.isOpen && item.type === 'folder'  && item.folderList ? 
            <ul >
              <>{renderItems(item.folderList, folderLevel+1, indexArray==='' ? '0' : indexArray + ',' + index)}</>
            </ul>
          : null}
          
          {/* 檔案清單 */}
          {item && item.isOpen && item.fileList && (
            <ul>
              {item.fileList.map((fileItem, fileIndex) => (
                <li key={fileIndex} onClick={() => handleFileClick(fileItem, fileIndex)}>
                  {(selectedButton==='ALL' || fileItem.imageStatus===selectedButton) && (
                    <div className='flex  p-[3px] w-[100%] border-b-[1px] border-[#E0E0E0] hover:bg-[#E6E6E6]'>
                      <div className='flex  w-[70%] justify-start'>
                        {'　'.repeat(folderLevel + 2)} <PictureOutlined/><span>&nbsp;</span>{fileItem.imageId}
                      </div>
                      <div className='flex justify-end w-[30%]'>
                        <>
                          {fileItem.imageId !== markingImageId && fileItem.imageStatus === 'MARKED' && (
                            <p className="bg-[#D9D9D9] text-[15px] text-center p-[3px] rounded-[4px] mr-[10px]">
                              已標記
                            </p>
                          )}
                          {fileItem.imageId !== markingImageId && fileItem.imageStatus === 'UNMARK' && (
                            <p className="bg-[#FFD4D4] text-[15px] text-center p-[3px] rounded-[4px] mr-[10px]">
                              未標記
                            </p>
                          )}
                          {fileItem.imageId === markingImageId && (
                            <p className="bg-[#FFECAF] text-[15px] text-center p-[3px] rounded-[4px] mr-[10px]">
                              標記中
                            </p>
                          )}
                        </>
                        {/* 刪除圖片  使用 event 避免事件冒泡 */}
                        <CloseOutlined onClick={(event) => { event.stopPropagation(); deleteImage(fileItem.imageId); }} />
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      ));
    }
    return '';    
  };
  
  return (
    <>
      <div className='flex h-[15%] w-[100%] p-[20px] items-center justify-between bg-[#F2F2F2] ' style={{ flexWrap: 'wrap' }}>

          <h3 className='text-[#3E3D3D] text-[23px] font-semibold'>肌膚資料庫列表</h3>
          {/* upload file */}
          <img src='./upload.png' alt='upload' 
          onClick={() => setShowUpload(true)} className='cursor-pointer'/>
          {showUpload && <UploadWindow onClose={() => setShowUpload(false)} refreshSidebar={refreshSidebar} />}

          <div className='flex w-[100%]'>
            <ButtonGroup className='flex w-[100%]' variant="contained"  aria-label="contained primary button group">
            <Button style={{ flex: 1 }} color={selectedButton === 'ALL' ? 'primary' : 'default'} onClick={() => setSelectedButton('ALL')}>全部</Button>
            <Button style={{ flex: 1 }} color={selectedButton === 'MARKED' ? 'primary' : 'default'} onClick={() => setSelectedButton('MARKED')}>已標記</Button>
            <Button style={{ flex: 1 }} color={selectedButton === 'UNMARK' ? 'primary' : 'default'} onClick={() => setSelectedButton('UNMARK')}>未標記</Button>
            </ButtonGroup>
          </div>
        </div>
        <div className='overflow-y-auto h-[85%]'> 
          <ul>
             {fileList ? renderItems(fileList.folderList, -1, '') : null}
          </ul>
        </div>
    </>
  );
};

export default FileSidebar;
