import React, { useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import  SystemConstants  from '../config/SystemConstants'

function MarkHistory  ({selectedImage, choose}) {
    const [issues, setIssues] = useState([])

    useEffect(() => {
        if(!selectedImage) return;
        const issueTypes = {
            ACNE:[],
            PIMPLES: [],
            OILINESS: [],
            WRINKLES: [],
            REDNESS: [],
            PORES: [],
            BROKEN_CAPILLARIES: [],
            DARK_SPOTS: [],
            DEHYDRATION: [],
            DARK_CIRCLES: [],
            EYE_BAGS: []   
        };
        if (selectedImage.markCoordinateList && selectedImage.markCoordinateList.length > 0) {
            selectedImage.markCoordinateList.forEach(issue => {
            // 確保skinIssueType存在且非空
            if (issue.skinIssueType && issue.skinIssueCoordinateList) {
                const type = issue.skinIssueType.toUpperCase();
                if (issueTypes[type] !== null) {
                    issueTypes[type].push({
                        level: issue.skinIssueLevel,
                        coordinates: issue.skinIssueCoordinateList
                    });
                    setIssues(issueTypes);
                }if (issueTypes[type] == null) {
                console.log('setIssues error');
                }
            }
        });
       } else {
      console.log('No markCoordinateList found or it is empty');
      setIssues(issueTypes);
       }
    }, [selectedImage]);

    const issuesToMarkCoordinateList = (issues) => {
        const markCoordinateList = [];
    
        Object.entries(issues).forEach(([type, issuesList]) => {
            issuesList.forEach(issue => {
                markCoordinateList.push({
                    skinIssueType: type, // 這裡可能需要根據實際情況調整類型的命名
                    skinIssueLevel: issue.level,
                    skinIssueCoordinateList: issue.coordinates
                });
            });
        });
    
        return markCoordinateList;
    };

    const handleDeleteIssue = (issueType, index) => {
        const updatedIssues = { ...issues };
        if (updatedIssues[issueType]) {
            updatedIssues[issueType].splice(index, 1);
            setIssues(updatedIssues);
            const updatedMarkCoordinateList = issuesToMarkCoordinateList(updatedIssues);
            const payload = {
                imageId: selectedImage.imageId,
                fileName: selectedImage.fileName,
                imageFileBase64: selectedImage.imageFileBase64,
                imageStatus: "MARKED",
                skinType: selectedImage.skinType, 
                markCoordinateList: updatedMarkCoordinateList
            };

            fetch(SystemConstants.API_URL.IMAGE.patchImageDetail, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
            .then(response => response.json())
            .then(data => {
                choose({imageId:selectedImage.imageId}); 
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
    };

      return(
        <div className='font-semibold'>
            <div className='flex h-[10%] p-[20px] justify-between bg-[#F2F2F2]'>
                <h3 className='text-[#3E3D3D] text-[23px] font-semibold'>標記紀錄</h3>
            </div>
            <ul className='h-[90%] overflow-auto'>
                {Object.entries(issues).map(([type, issuesList]) => (
                    <>
                        <li key={type} className='flex justify-between items-center w-[100%] p-[10px] text-[17px] bg-[#F7F7F7] border-[1px] border-[#E0E0E0]'>
                            {SystemConstants.ISSUE_TYPE_MAPPING[type].name || type} ({issuesList.length})
                            <span className='w-[12px] h-[12px] rounded-[50%]' style={{ backgroundColor: SystemConstants.ISSUE_TYPE_MAPPING[type].color }}>
                            </span>
                        </li>
                        {issuesList.map((issue, index) => (
                            <div key={index} className="w-[100%] flex justify-between p-2 bg-[#F0F0F0] border-b-[1px] border-[#E0E0E0]">
                                <p>{(index + 1).toString().padStart(3, '0')}</p>
                                <div className='flex'>
                                    <p className='mr-[7px]'>嚴重度: {issue.level.split('_')[1]}</p>
                                    <CloseOutlined onClick={() => handleDeleteIssue(type, index)} />
                                </div>
                            </div>
                        ))}
                    </>
                ))}
            </ul>
    </div>

      )

}

export default MarkHistory;