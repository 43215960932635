
import './App.css';
import { useEffect, useState } from 'react';
import ImageDetails from './components/ImageDetails';
import FileSidebar from './components/FileSidebar'
import MarkHistory from './components/MarkHistory';

// 系統參數
import  SystemConstants  from './config/SystemConstants';

function App() {
  const [photodata, setphotoData] = useState([])
  const [selectedImage, setSelectedImage] = useState(null);
  const [sidebarKey, setSidebarKey] = useState(1);

  // 藉由 key+1 刷新 Sidebar  
  const refreshSidebar = () => {
    setSidebarKey(sidebarKey+1)
  }

  const choose = (photodata) => {
    const url = SystemConstants.API_URL.IMAGE.getImageDetail + `${photodata.imageId}`;
    fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data.status === '0' && data.responseData) {
        setSelectedImage(data.responseData);  
        } else {
            console.error('Failed to fetch image details:', data.msg);
        }
    })
    .catch(error => {
      console.error('Error fetching image details:', error);
    });
  };
  
  return (
    <div className='flex w-[100%] '>
      <aside className=' w-[24%] max-h-[100vh] '>
        {/* 側邊攔 */}
          <FileSidebar key={sidebarKey} choose={choose} refreshSidebar={refreshSidebar} />    
          {/* {photodata && photodata.map( (data) => {
            return(
            <div key={data.imageId} className='flex justify-between items-center p-[10px] w-[100%] border-b-[1px] border-[#E0E0E0] hover:bg-[#E6E6E6] h-[80px]'>
              <div onClick={() => choose(data)} className='flex items-center w-[70%] justify-start'>
                <img src={`data:image/jpeg;base64,${data.thumbnailBase64}`} alt={`Image ${data.imageId}`} className='w-[36px] h-[36px] mr-[7px]' />
                <p className='text-[17px] font-semibold'>{data.fileName}</p>
              </div>
              <div className='flex justify-end w-[30%]'>
                <p className = {`${data.status === 'MARKED' ? 'bg-[#D9D9D9]' : 'bg-[#FFD4D4]'} text-[15px] text-center p-[3px] rounded-[4px] mr-[10px]`}>
                  {data.status === 'MARKED' ? '已標記' : '未標記'}
                </p>
                <CloseOutlined onClick={() => deleteImage(data === null ? 0 : data.imageId)} />
              </div>
            </div>)})} */}
        
      </aside>
      <main className=' w-[52%] h-[100vh]'>
        <ImageDetails selectedImage={selectedImage} choose={choose} refreshSidebar={refreshSidebar}/>
      </main>
      <div className=' w-[24%] h-[100vh]'>
        <MarkHistory selectedImage={selectedImage} choose={choose}/>
      </div>
    </div>
  );
}

export default App;

