// config/SystemConstants.js

// 後端路徑
// 測試環境
const BACKEND_PATH = 'https://panco.stapi.scfg.io';

// 本機環境
// const BACKEND_PATH = 'http://localhost:8080';

export const SYSTEM_CONSTANTS = {
    BACKEND_PATH: BACKEND_PATH,

    // API 的 URL 後綴
    API_URL :{
        IMAGE:{
            getThumbnailList : BACKEND_PATH + '/image/getThumbnailList',            // 縮圖列表
            getImageDetail : BACKEND_PATH + '/image/getImageDetail?imageId=',       // 主要圖片資訊
            deleteImage : BACKEND_PATH + '/image/deleteImage',                      // 刪除圖片
            patchImageDetail : BACKEND_PATH + '/image/patchImageDetail',          // 更新圖片
            uploadImageDetail : BACKEND_PATH + '/image/uploadImageDetail',          // 上傳圖片
            getPathInfo : BACKEND_PATH + '/image/getPathInfo?path='                 // 取得指定資料夾內容
        }
    },

    // 各肌膚問題的顏色
    ISSUE_TYPE_MAPPING: {
        ACNE: { name: '面皰', color: '#F0BF11' },
        PIMPLES: { name: '粉刺', color: '#00A825' },
        OILINESS: { name: '出油', color: '#CB43ED' },
        WRINKLES: { name: '皺紋', color: '#524FFF' },
        REDNESS: { name: '泛紅', color: '#FF5E19' },
        PORES: { name: '毛孔', color: '#FF9097' },
        BROKEN_CAPILLARIES: { name: '血絲', color: '#FFC6C6' },
        DARK_SPOTS: { name: '斑點', color: '#B7A500' },
        DEHYDRATION: { name: '缺水', color: '#FAFF00' },
        DARK_CIRCLES: { name: '黑眼圈', color: '#9BC39F' },
        EYE_BAGS: { name: '眼袋', color: '#D4D4D4' }
    }
    
}

export default SYSTEM_CONSTANTS;
