import React, { useState, useEffect, useRef } from 'react';
import { Rate } from 'antd';
import  SystemConstants  from '../config/SystemConstants'

function ImageDetails  ({selectedImage, choose, refreshSidebar}) {  
    const [image, setImage] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
    const [endPoint, setEndPoint] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(1);
    const [skinIssueType, setSkinIssueType] = useState(null);
    const [issueLevel, setIssueLevel] = useState(0);
    const [skinType, setSkinType] = useState('');
    const canvasRef = useRef(null);
    const [originalProportionRate, setOriginalProportionRate] = useState(0)

    // init 縮放率
    const [autoZoomRate, setAutoZoomRate] = useState(0);

    // 大圖完成後才畫標線
    useEffect(() => {
        if(image){
            setIsDrawing(true)
            drawCanvas(image, scale);
            setIsDrawing(false)
        }
    },[image])

    // 更新大圖
    useEffect(() => {
        if (!selectedImage || !canvasRef.current) return;


        setSkinType(selectedImage.skinType? selectedImage.skinType : '')

    
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.src = `${selectedImage.publicUrl}`
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            const parentWidth = window.innerWidth;
            const parentHeight = window.innerHeight;
            const parentAspectRatio = parentWidth / parentHeight;
        
            // 判斷要放大或縮小
            if (aspectRatio > parentAspectRatio) {
                canvas.width = parentWidth;
                canvas.height = parentWidth / aspectRatio;
            } else {
                canvas.height = parentHeight;
                canvas.width = parentHeight * aspectRatio;
            }

            const rect = canvas.getBoundingClientRect();

            // 縮放率  (圖片原始寬/實際DOM寬)
            setAutoZoomRate(canvas.width / rect.width)
            console.log('真實原圖寬/高', img.width, img.height)          // 600 * 600
            console.log('長寬比例使用寬/高', canvas.width, canvas.height)   // 695 * 695
            console.log('實際呈現寬/高', rect.width, rect.height)         // 532.5375366210938 * 532.5375366210938

            // 計算長寬比和原圖的比例，作為初始繪圖與打 API 最後轉換使用
            // 呈現比例使用寬度 / 原圖寬
            setOriginalProportionRate(canvas.width / img.width);

            

            // 清除再刷新 ctx
            ctx.clearRect(0, 0, ctx.width, ctx.height);
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            setImage(img);
        };
        img.onerror = () => {
            console.error('Failed to load image');
        }
    }, [selectedImage]);

    

    // 刪除照片
    const deleteImage = () => {
        fetch(SystemConstants.API_URL.IMAGE.deleteImage, {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(
            {
                imageId: selectedImage.imageId               
            }),
        })
        .then(response => response.json())
        .then(data => {
            // 刪除照片後，透過 callBack 刷新Sidebar
            refreshSidebar();

        })
        .catch(error => console.error('Error:', error));
    };
    
    // 點下滑鼠事件(起始點)
    const handleMouseDown = (e) => {

        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setStartPoint({ x, y });
        setIsDrawing(true);
    };

    // 移動滑鼠事件(紀錄位移)
    const handleMouseMove = (e) => {
        if (!isDrawing) return;
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setEndPoint({ x, y });
        console.trace('trace')
        drawCanvas(image, scale); // 使用當前 image 和 scale 繪製
    };


    // 放開滑鼠事件(固定框框)
    const handleMouseUp = (e) => {
        setIsDrawing(false);
    };

    // 畫圖邏輯
    const drawCanvas = (image, scale) => {
        const canvas = canvasRef.current;
        console.log(canvas)
        console.log(image)
        if (!canvas || !image) return; 
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        let countArray = [];
    
        // 繪製由 API 加載的框線
        selectedImage.markCoordinateList.forEach(mark => {
            const { skinIssueCoordinateList, skinIssueType } = mark;
            if (!skinIssueCoordinateList || skinIssueCoordinateList.length === 0) return;
            const x = Math.min(skinIssueCoordinateList[0].x * originalProportionRate, skinIssueCoordinateList[2].x  * originalProportionRate);
            const y = Math.min(skinIssueCoordinateList[0].y * originalProportionRate, skinIssueCoordinateList[2].y * originalProportionRate) ;
            const width = Math.abs(skinIssueCoordinateList[2].x - skinIssueCoordinateList[0].x) * originalProportionRate;
            const height = Math.abs(skinIssueCoordinateList[2].y - skinIssueCoordinateList[0].y) * originalProportionRate;
            const color = skinIssueType ? SystemConstants.ISSUE_TYPE_MAPPING[skinIssueType].color : '';
            ctx.beginPath();
            ctx.rect(x, y, width, height);
            ctx.strokeStyle = color;
            ctx.lineWidth = 1;
            ctx.stroke();

            // 計算標記字串的位置（假設標記字串為 'Label'）
            const labelX = x; // 矩形右邊界
            const labelY = y-2; // 矩形上邊界
            
            // 繪製填滿矩形作為底色
            ctx.fillStyle = SystemConstants.ISSUE_TYPE_MAPPING[skinIssueType].color; // 設定底色為肌膚問題顏色
            ctx.fillRect(labelX, labelY - 16, ctx.measureText('Label').width, 18); 

            if(countArray[skinIssueType]){
                countArray[skinIssueType]++;
            } else{
                countArray.push(skinIssueType)
                countArray[skinIssueType] = 1;
            }

            // 繪製標記字串
            ctx.font = '16px Arial';
            ctx.fillStyle = 'black'; // 設定文字顏色為白色

            // 用 001 002 的文字格式
            ctx.fillText(countArray[skinIssueType].toString().padStart(3, '0'), labelX, labelY-2);
        });
        // 如果正在繪製，顯示當前的框線
        if (isDrawing ) {
            ctx.beginPath();
            ctx.rect(startPoint.x * autoZoomRate, startPoint.y * autoZoomRate, (endPoint.x - startPoint.x) * autoZoomRate, (endPoint.y - startPoint.y) * autoZoomRate);
            ctx.strokeStyle = 'blue'; // 繪製新框線的顏色
            ctx.lineWidth = 1;
            ctx.stroke();
        }
    };

    const handleSkinIssueChange = (e) => {
        setSkinIssueType(e.target.id);  
    };
    
    const handleRateChange = (value) => {
        setIssueLevel(value);  
    };

    const handleSkinTypeChange = (e) => {

        // 打更新主要圖片API
        handleSubmit(true, e.target.id);
    };

    // 如果只更新肌膚基礎類型，則 onlyUpdateSkinType 會是 true
    const handleSubmit = async (onlyUpdateSkinType, changeSkinType) => {

        // 把原本已標記的放進陣列
        let updatedMarkCoordinateList =[...selectedImage.markCoordinateList];
        
        // 非只更新肌膚基礎類型，則需要驗證跟加入新的問題標記矩形
        if(onlyUpdateSkinType !== true){

            // 如果要更新肌膚標記，就需要先通過驗證
            if (!skinIssueType || !issueLevel || (startPoint.x === 0 && startPoint.y === 0) || (endPoint.x === 0 && endPoint.y === 0)) {
                alert('資料不完整，請選取完整資料！');
                return;
            }; 
            
            const createNewMark = {
                skinIssueType: skinIssueType,
                skinIssueLevel: `LEVEL_${issueLevel}`,
                skinIssueCoordinateList:[
                    { x: Math.round(startPoint.x * autoZoomRate / originalProportionRate), y: Math.round(startPoint.y * autoZoomRate / originalProportionRate) },
                    { x: Math.round(startPoint.x * autoZoomRate / originalProportionRate), y: Math.round(endPoint.y * autoZoomRate / originalProportionRate) },
                    { x: Math.round(endPoint.x * autoZoomRate / originalProportionRate), y: Math.round(endPoint.y * autoZoomRate / originalProportionRate) },
                    { x: Math.round(endPoint.x * autoZoomRate / originalProportionRate), y: Math.round(startPoint.y * autoZoomRate / originalProportionRate) }
                ]
            };
            updatedMarkCoordinateList.push(createNewMark);
        }

        const payload = {
            imageId: selectedImage.imageId,  
            fileName: selectedImage.fileName,
            imageFileBase64: selectedImage.imageFileBase64,
            imageStatus: "MARKED",
            skinType: changeSkinType? changeSkinType : skinType,    // 如果有給定就使用給定的，不然就維持原設定
            markCoordinateList: updatedMarkCoordinateList
        };

        try {
            const response = await fetch(SystemConstants.API_URL.IMAGE.patchImageDetail, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            setSkinIssueType(null);
            setIssueLevel(0);
            setStartPoint({ x: 0, y: 0 }); 
            setEndPoint({ x: 0, y: 0 }); 
            choose({imageId:selectedImage.imageId}); 
        } catch (error) {
            console.error('Error submitting the data:', error);
        };  
    };

    const handleCancel = () => {
        setSkinIssueType(null);
        setIssueLevel(0);
        setStartPoint({ x: 0, y: 0 });  
        setEndPoint({ x: 0, y: 0 });
        setIsDrawing(false); 
        drawCanvas(image);
    };

    return(
        <div className='flex flex-col w-[100%] h-[100vh] border-[#E0E0E0] border-[1px] px-4 py-2 gap-1'>
            <div className='flex justify-between w-full items-center'>
                <div className='flex w-fit justify-around items-center bg-[#E7E7E7] rounded-[10px] p-[6px] gap-4'>
                    <h3 className='font-semibold text-[17px]'>基礎類型</h3>
                    <div className='flex item-box'>
                        <label htmlFor='SKIN_TYPE_DRY' className='item-list flex items-center'>
                            <input type='checkbox' id='SKIN_TYPE_DRY' value={skinType} checked={skinType === 'SKIN_TYPE_DRY'} onChange={handleSkinTypeChange}></input>
                            乾性
                        </label>
                    </div> 
                    <div className='flex  item-box'>
                        <label htmlFor='SKIN_TYPE_OILY' className='item-list flex items-center'>
                            <input type='checkbox' id='SKIN_TYPE_OILY' value={skinType} checked={skinType === 'SKIN_TYPE_OILY'} onChange={handleSkinTypeChange}></input>
                            油性
                        </label>
                    </div>
                    <div className='flex  item-box'>
                        <label htmlFor='SKIN_TYPE_NORMAL' className='item-list flex items-center'>
                            <input type='checkbox' id='SKIN_TYPE_NORMAL' value={skinType} checked={skinType === 'SKIN_TYPE_NORMAL'} onChange={handleSkinTypeChange}></input>
                            中性
                        </label>
                    </div>
                    <div className='flex  item-box'>
                        <label htmlFor='SKIN_TYPE_COMBINATION' className='item-list flex items-center'>
                            <input type='checkbox' id='SKIN_TYPE_COMBINATION' value={skinType} checked={skinType === 'SKIN_TYPE_COMBINATION'} onChange={handleSkinTypeChange}></input>
                            混合性
                        </label>
                    </div>
                    <div className='flex  item-box'>
                        <label htmlFor='SKIN_TYPE_SENSITIVE' className='item-list flex items-center'>
                            <input type='checkbox' id='SKIN_TYPE_SENSITIVE' value={skinType} checked={skinType === 'SKIN_TYPE_SENSITIVE'} onChange={handleSkinTypeChange}></input>
                            敏感性
                        </label>
                    </div>
                </div>
                <div className='flex flex-row w-fit gap-2'>
                    {/* <button className='flex h-[28px] w-[50px] justify-center items-center p-[7px] text-[17px] font-semibold border-[1px] border-[#606060] rounded-[20px]'>
                        +
                    </button>
                    <button className='flex h-[28px] w-[50px] justify-center items-center p-[7px] text-[17px] font-semibold border-[1px] border-[#606060] rounded-[20px]'>
                        -
                    </button> */}
                    <button className='flex h-[28px] w-[107px] justify-center items-center p-[7px] text-[17px] font-semibold border-[1px] border-[#606060] rounded-[20px]' onClick={deleteImage} >
                        刪除照片
                    </button>
                </div>
            </div>
            <div className='flex w-full h-[1px] justify-center items-center bg-[#E0E0E0] my-2'></div>
            
            <div className='flex w-full justify-between items-start text-[14px] font-normal gap-0'>
                <h3 className='font-semibold text-[17px] '>
                    肌膚問題
                    <span className='text-[red]'>*</span>
                </h3>
                <div className='flex w-fit justify-around pb-[9px] item-box'>
                    <label htmlFor='ACNE' className='item-list flex items-center'>
                        <input type='checkbox' id='ACNE' checked={skinIssueType === 'ACNE'} onChange={handleSkinIssueChange} readOnly ></input>
                        面皰
                    </label>
                </div>
                <div className='flex w-fit justify-around pb-[9px] item-box'>
                    <label htmlFor='PIMPLES' className='item-list flex items-center'>
                        <input type='checkbox' id='PIMPLES' checked={skinIssueType === 'PIMPLES'} onChange={handleSkinIssueChange} readOnly></input>
                        粉刺
                    </label>
                </div>
                <div className='flex w-fit justify-around pb-[7px] item-box'>
                    <label htmlFor='REDNESS' className='item-list flex items-center'>
                        <input type='checkbox' id='REDNESS' checked={skinIssueType === 'REDNESS'} onChange={handleSkinIssueChange} readOnly></input>
                        泛紅
                    </label>
                </div>
                <div className='flex w-fit justify-around pb-[9px] item-box'>
                    <label htmlFor='BROKEN_CAPILLARIES' className='item-list flex items-center'>
                        <input type='checkbox' id='BROKEN_CAPILLARIES' checked={skinIssueType === 'BROKEN_CAPILLARIES'} onChange={handleSkinIssueChange} readOnly></input>
                        紅血絲
                    </label>
                </div>
                <div className='flex w-fit justify-around pb-[9px] item-box'>
                    <label htmlFor='DARK_SPOTS' className='item-list flex items-center'>
                        <input type='checkbox' id='DARK_SPOTS' checked={skinIssueType === 'DARK_SPOTS'} onChange={handleSkinIssueChange}  readOnly></input>
                        斑點
                    </label>
                </div>
                <div className='flex w-fit justify-around pb-[9px] item-box'>
                    <label htmlFor='WRINKLES' className='item-list flex items-center'>
                        <input type='checkbox' id='WRINKLES' checked={skinIssueType === 'WRINKLES'} onChange={handleSkinIssueChange} readOnly></input>
                        皺紋
                    </label>
                </div> 
                <div className='flex w-fit justify-around pb-[5px] item-box'>
                    <label htmlFor='DEHYDRATION' className='item-list flex items-center'>
                        <input type='checkbox' id='DEHYDRATION' checked={skinIssueType === 'DEHYDRATION'} onChange={handleSkinIssueChange} readOnly></input>
                        缺水度
                    </label>
                </div>
                <div className='flex w-fit justify-around pb-[5px] item-box'>
                    <label htmlFor='OILINESS' className='item-list flex items-center'>
                        <input type='checkbox' id='OILINESS' checked={skinIssueType === 'OILINESS'} onChange={handleSkinIssueChange} readOnly></input>
                        出油度
                    </label>
                </div> 
                <div className='flex w-fit justify-around pb-[5px] item-box'>
                    <label htmlFor='PORES' className='item-list flex items-center'>
                        <input type='checkbox' id='PORES' checked={skinIssueType === 'PORES'} onChange={handleSkinIssueChange} readOnly></input>
                        毛孔
                    </label>
                </div> 
                <div className='flex w-fit justify-around pb-[5px] item-box'>
                    <label htmlFor='DARK_CIRCLES' className='item-list flex items-center'>
                        <input type='checkbox' id='DARK_CIRCLES' checked={skinIssueType === 'DARK_CIRCLES'} onChange={handleSkinIssueChange} readOnly></input>
                        黑眼圈
                    </label>
                </div> 
                <div className='flex w-fit justify-around pb-[5px] item-box'>
                    <label htmlFor='EYE_BAGS' className='item-list flex items-center'>
                        <input type='checkbox' id='EYE_BAGS' checked={skinIssueType === 'EYE_BAGS'} onChange={handleSkinIssueChange} readOnly></input>
                        眼袋
                    </label>
                </div>
            </div>
            
            <div className='flex flex-row justify-between items-center pb-[16px] w-[100%] border-[#E0E0E0] border-b-[1px] font-medium text-[14px]'>
            <div className='flex w-fit'>
                <h3 className='font-semibold text-[17px] justify-start mr-7'>
                    程度選擇
                    <span className='text-[red]'>*</span>
                </h3>
                <div className='flex w-fit justify-around items-center w-[100%] text-[14px] gap-2'>
                    <p>輕微</p>
                    <Rate onChange={handleRateChange} value={issueLevel} className='text-[24px]' />
                    <p>嚴重</p>
                </div>
            </div>
                <div className='flex flex-row w-fit gap-2'>
                    <button className='w-[60px] h-[28px] bg-[#D9D9D9] border-[#C2C2C2] border-[1px] rounded-[3px] text-[14px] font-semibold'
                        onClick={handleCancel}>
                            取消</button>
                    <button className='w-[60px] h-[28px] bg-[#B3E4B8] border-[#C2C2C2] border-[1px] rounded-[3px] font-semibold'
                        onClick={handleSubmit}>
                            確定</button>
                </div>
            </div>
            {/* 圖片標示  */}
            <div className='max-w-[52vw] max-h-[83vh] flex justify-center overflow-auto'>
                  <canvas  
                    //   不呈現 垂直/水平 卷軸
                    style={{ overflowY: 'scroll', overflowX: 'hidden' }}
                    ref={canvasRef} 
                    onMouseDown={handleMouseDown} 
                    onMouseMove={handleMouseMove} 
                    onMouseUp={handleMouseUp} />
            </div>
        </div>
    )
};

export default ImageDetails;